<template>
  <div v-if="categories" class="recipes">
    <div class="container">
      <div class="recipes__nav">
        <locale-router-link to="" class="crumbs-link main-page-link">
          {{ $t("home") }}
        </locale-router-link>
        <locale-router-link
          to="cooking"
          class="crumbs-link cooking-with-magnum"
          >{{ $t("cookingWithMagnum") }}</locale-router-link
        >
        <a class="crumbs-link recipes-link"> {{ $t("recipes") }}</a>
      </div>

      <h1 class="title">{{ $t("recipes") }}</h1>

      <div class="recipes-filter">
        <div class="recipes-filter__title is-active">
          {{ $t("pickRecipes") }}
        </div>
        <div class="recipes-filter__items is-active">
          <div class="recipes-filter__top">
            <div
              @click="drawAllRecipes"
              class="recipes-filter__item"
              :class="{ 'is-active': isAllRecipes }"
            >
              <div class="recipes-filter__name">
                {{ $t("allRecipes") }}
              </div>
              <span v-if="allRecipes" class="recipes-filter__amount">{{
                allRecipes.length
              }}</span>
            </div>
            <div
              v-for="(category, index) in categories"
              :key="index"
              class="recipes-filter__item"
              :class="{ 'is-active': category.id === selectedCategory.id }"
              @click="getRecipes(category)"
            >
              <div class="recipes-filter__name">
                {{ category.attributes.label }}
              </div>
              <span class="recipes-filter__amount">{{
                category.attributes.recipes.data.length
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- :class="{ 'is-active': item.id === selectedCategory.id }" -->
      <!-- {{ category.attributes.recipes.data }} -->
      <recipe-list v-if="items" :items="items" />
    </div>
  </div>
</template>

<script>
import { getRecipesCategories } from "@/api/recipe";
export default {
  name: "PageRecipes",
  components: {
    RecipeList: () => import("@/components/recipe/RecipeList.vue"),
  },
  data() {
    return {
      allRecipes: [],
      items: null,
      categories: [],
      selectedCategory: [],
      isAllRecipes: true,
      anyTaste: {
        ru: [6, 9, 13, 781496],
        kz: [10, 7, 781497, 781493],
      },
      lunchAndDinner: {
        ru: [1, 781494],
        kz: [781495, 11],
      },
      pagination: {
        page: 1,
        pageSize: 100,
      },
      sortType: "desc",
    };
  },

  created() {
    switch (this.$route.query.type) {
      case "anyTaste":
        getRecipesCategories(
          this.$i18n.locale === "kz" ? this.anyTaste.kz : this.anyTaste.ru
        ).then((categories) => {
          this.categories = categories.data;
          console.log(this.categories);
          this.categories.forEach((e) => {
            this.allRecipes.push(...e.attributes.recipes.data);
          });
          this.items = this.allRecipes;
        });
        break;
      case "lunchAndDinner":
        getRecipesCategories(
          this.$i18n.locale === "kz"
            ? this.lunchAndDinner.kz
            : this.lunchAndDinner.ru
        ).then((categories) => {
          this.categories = categories.data;
          this.categories.forEach((e) => {
            this.allRecipes.push(...e.attributes.recipes.data);
          });
          this.items = this.allRecipes;
        });
        break;
    }
  },
  methods: {
    getRecipes(item) {
      this.items = item.attributes.recipes.data;
      this.selectedCategory = item;
      this.isAllRecipes = false;
    },
    drawAllRecipes() {
      if (this.isAllRecipes) {
        return;
      }
      this.selectedCategory = [];
      this.isAllRecipes = true;
      this.items = this.allRecipes;
    },
  },
};
</script>

<style lang="scss" scoped></style>
