import axios from "@/plugins/axios";
import axiosUninterseptor from "@/plugins/axiosUninterseptor";
import qs from "qs";

export const getRecipesCategories = async (ids) => {
  const query = qs.stringify(
    {
      populate: {
        recipes: {
          populate: {
            image: "*",
            author: {
              populate: "*",
            },
            recipe_details: "*",
            recipe_category: "*",
            localizations: "*",
          },
        },
      },
      filters: {
        id: {
          $in: ids,
        },
      },
    },
    {
      encodeValuesOnly: true,
    }
  );
  const response = await axios.get(`/recipe-categories?${query}`);
  return response?.data;
};

export const getAllRecipes = async (
  page = 1,
  pageSize = 100,
  sortType = "desc",
  excludeId
) => {
  const queryFilters = { filters: {} };
  if (excludeId) {
    queryFilters.filters["id"] = {
      $ne: excludeId,
    };
  }
  const query = qs.stringify(
    {
      pagination: {
        page: page,
        pageSize: pageSize,
      },
      populate: {
        image: "*",
        localizations: "*",
        author: {
          populate: "*",
        },
        recipe_details: "*",
        recipe_category: "*",
      },
      ...queryFilters,
      sort: [`createdAt:${sortType}`],
    },
    {
      encodeValuesOnly: true,
    }
  );
  const response = await axios.get(`/recipes?${query}`);
  return response?.data;
};

export const getAllRecipesByFilter = async (
  filters,
  page = 1,
  pageSize = 100,
  sortType = "desc",
  excludeId
) => {
  const queryFilters = { filters: {} };
  if (excludeId) {
    queryFilters.filters["id"] = {
      $ne: excludeId,
    };
  }
  if (filters) {
    queryFilters.filters.recipe_category = {
      label: {
        $in: filters,
      },
    };
  }

  const query = qs.stringify(
    {
      pagination: {
        page: page,
        pageSize: pageSize,
      },
      populate: {
        image: "*",
        localizations: "*",
        author: {
          populate: "*",
        },
        recipes: {
          image: "*",
          localizations: "*",
          author: {
            populate: "*",
          },
        },
        recipe_details: "*",
        recipe_category: "*",
      },
      ...queryFilters,
      sort: [`createdAt:${sortType}`],
    },
    {
      encodeValuesOnly: true,
    }
  );
  const response = await axios.get(`/recipes?${query}`);
  return response?.data;
};

export const getRecipe = async (id) => {
  const query = qs.stringify(
    {
      populate: {
        image: "*",
        localizations: "*",
        author: {
          populate: "*",
        },
        nutrition_facts: {
          populate: {
            localizations: "*",
          },
        },
        recipe_details: {
          populate: {
            localizations: "*",
            related_categories: "*",
          },
        },
        recipe_category: {
          populate: {
            localizations: "*",
          },
        },
        steps: {
          populate: {
            img: "*",
            localizations: "*",
          },
        },
      },
    },
    {
      encodeValuesOnly: true,
    }
  );
  const response = await axiosUninterseptor.get(`/recipes/${id}?${query}`);
  return response?.data?.data;
};
